"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.limitChar = limitChar;

function limitChar(str, length) {
  if (str && typeof str === "string" && length > 6 && str.length > length) str = "".concat(str.substring(0, length - 3), "..."); // console.log('string char', str);

  return str;
}
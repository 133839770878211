"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fillTemplate = void 0;

//@ts-nocheck
var fillTemplate = function fillTemplate(templateString, templateVariables) {
  return templateString.replace(/\${(.*?)}/g, function (_, g) {
    return templateVariables[g];
  });
};

exports.fillTemplate = fillTemplate;
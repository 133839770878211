"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uuidToHex = void 0;

//@ts-ignore
var uuidParse = require("uuid-parse");

var isUUID = require("validator/lib/isUUID");

var uuidToHex = function uuidToHex(uuidString) {
  var addLeadingZero = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!isUUID(uuidString)) {
    throw new Error("uuidString is not valid UUID");
  } //Allocate 16 bytes for the uuid bytes representation


  var uuidBuffer = Buffer.alloc(16); //Parse uuid string representation and send bytes into buffer

  uuidParse.parse(uuidString, uuidBuffer); //Create strict uuid hex representation

  var uuidHex = uuidBuffer.toString("hex");
  return addLeadingZero ? "0x" + uuidHex : uuidHex;
};

exports.uuidToHex = uuidToHex;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isJson = void 0;

var isJson = function isJson(str) {
  try {
    return JSON.parse(str);
  } catch (err) {
    return false;
  }
};

exports.isJson = isJson;
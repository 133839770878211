"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arrayMerger = arrayMerger;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//@ts-nocheck
function arrayMerger(a, b, key) {
  if (a && Array.isArray(a) && b && Array.isArray(b)) {
    var merged = _lodash.default.merge(_lodash.default.keyBy(a, key), _lodash.default.keyBy(b, key));

    var values = _lodash.default.values(merged);

    return values;
  } else {
    throw new Error("Error in input arguments");
  }
}
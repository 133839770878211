"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAvatarPicture = void 0;

var is = require("is_js");

var getAvatarPicture = function getAvatarPicture(url) {
  if (url && is.string(url) && is.existy(url)) {
    return url;
  }

  if (url !== null && url !== void 0 && url.uri && is.not.empty(url === null || url === void 0 ? void 0 : url.uri)) {
    return url;
  } else {
    return null;
  }
};

exports.getAvatarPicture = getAvatarPicture;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nameMerger = nameMerger;

var is = require("is_js");

function nameMerger(name) {
  var matches = name === null || name === void 0 ? void 0 : name.match(/\b(\w)/g);

  if (matches && is.not.empty(matches)) {
    matches = matches.join("");

    if (matches.length > 2) {
      matches = String(matches).substring(0, 2);
    }
  }

  return matches;
}
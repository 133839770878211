"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchInArray = matchInArray;

function matchInArray(string, expressions) {
  var len = expressions === null || expressions === void 0 ? void 0 : expressions.length,
      i = 0;

  for (; i < len; i++) {
    if (string.match(expressions[i])) {
      return true;
    }
  }

  return null;
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hex2rgb = hex2rgb;

function hex2rgb(color) {
  var _color;

  /* Check for # infront of the value, if it's there, strip it */
  if (((_color = color) === null || _color === void 0 ? void 0 : _color.substring(0, 1)) == "#") {
    color = color.substring(1);
  }

  var rgbColor = {
    r: undefined,
    g: undefined,
    b: undefined
  };
  /* Grab each pair (channel) of hex values and parse them to ints using hexadecimal decoding */

  if (color) {
    var _color2, _color3, _color4;

    rgbColor.r = parseInt((_color2 = color) === null || _color2 === void 0 ? void 0 : _color2.substring(0, 2), 16);
    rgbColor.g = parseInt((_color3 = color) === null || _color3 === void 0 ? void 0 : _color3.substring(2, 4), 16);
    rgbColor.b = parseInt((_color4 = color) === null || _color4 === void 0 ? void 0 : _color4.substring(4), 16);
  }

  return rgbColor;
}
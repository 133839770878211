"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.maskEmail = void 0;

var maskEmail = function maskEmail(email) {
  return email === null || email === void 0 ? void 0 : email.replace(/^(.)(.*)(.@.*)$/, function (_, a, b, c) {
    return a + b.replace(/./g, "*") + c;
  });
};

exports.maskEmail = maskEmail;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRGB = void 0;

var hex2rgb = require("hex2rgb");

var getRGB = function getRGB(color, opacity) {
  var bg = hex2rgb(color, {
    rgbStringDefault: "transparent"
  }).rgb;
  return "rgba(".concat(bg[0], ", ").concat(bg[1], ", ").concat(bg[2], ", ").concat(opacity, ")");
};

exports.getRGB = getRGB;
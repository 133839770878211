"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pretty = pretty;

var _jsonColorizer = _interopRequireDefault(require("json-colorizer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function pretty(param) {
  var multiline = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var color = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (_typeof(param) === "object") {
    var _res = color ? (0, _jsonColorizer.default)(JSON.stringify(param, null, multiline ? 2 : 0)) : JSON.stringify(param, null, multiline ? 2 : 0);

    return _res;
  }

  return param;
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hexTouuid = void 0;

//@ts-ignore
var uuidParse = require("uuid-parse");

var isHex = require("validator/lib/isHexadecimal");

var hexTouuid = function hexTouuid(hexString) {
  var parsedHexString = hexString.replace(new RegExp("^0x"), "");

  if (!isHex(parsedHexString)) {
    throw new Error("hexString is not valid hexadecimal number");
  } //Allocate 16 bytes for the uuid bytes representation


  var hexBuffer = Buffer.from(parsedHexString, "hex"); //Parse uuid string representation and send bytes into buffer

  var uuidResultBuffer = uuidParse.unparse(hexBuffer); //Create uuid utf8 string representation

  return uuidResultBuffer.toString("utf8");
};

exports.hexTouuid = hexTouuid;